<template>
<div class="">
  <!-- <idUser></idUser> -->
  
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="12" xs="12" md="8" class="xleft">
        <v-img src="@/assets/logopwa.png" max-width="125" max-height="125"></v-img>    
        <!-- <h1 class="ml-2">Bodega Net</h1> -->
      </v-col>
      <v-col cols="12" xs="12" md="4" class="xright">
        <h2>LOGIN</h2>
          <v-form @submit.prevent="submit">
            <v-text-field v-model="contrato" label="Contrato" 
                required 
                outlined 
                dark 
                filled 
                dense
            ></v-text-field>
            <v-text-field v-model="login" label="Usuario" 
                required 
                outlined 
                dark 
                filled 
                dense
            ></v-text-field>
            <v-text-field v-model="password" label="Password" 
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
            ></v-text-field>
            <div class="text-center">
                <v-btn class="signin-btn" type="submit" rounded color="white" dark>Ingresar</v-btn>
            </div>
          </v-form>
          <v-row>
            <!-- <v-col cols="12" md="12">
              <v-chip class="mt-10" small dark @click="OpenDialog()">Cambiar Password</v-chip>
            </v-col> -->
            <v-col cols="12" md="12" class="mt-5">
              <v-chip class="mt-10" small dark @click="OpenFormDialog()">¿Olvidaste tu contraseña?</v-chip>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </section>

  <!-- dialogo contraseña olvidada -->
  <v-dialog v-model="form_dialog" max-width="380">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="8" md="10">
            Recuperar Contraseña
          </v-col>
          <v-col cols="4" md="2" class="text-end">
            <v-btn @click="form_dialog=false" text icon>
              <span class="red--text">X</span>
            </v-btn>
          </v-col>
        </v-row>
        </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field v-model="form_contrato" placeholder="Ingrese Contrato" outlined dense clearable></v-text-field>
              <v-text-field v-model="form_usuario" placeholder="Ingrese Usuario" required outlined dense clearable></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="teal" @click="sendPassword()" rounded small dark>Enviar</v-btn>
          <h6 class="mt-5">- La contraseña sera enviada a tu correo registrado en el sistema.</h6>
          <h6 class="mt-1">- Si no recibes el correo, por favor consulta al administrador.</h6>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- dialogo cambia password -->
    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="8" md="10">
              Cambiar Contraseña
            </v-col>
            <v-col cols="4" md="2" class="text-end">
              <v-btn @click="dialog=false" text icon>
                <span class="red--text">X</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="login" label="Usuario" 
                required 
                outlined 
                filled 
                dense
                disabled
            ></v-text-field>
            <v-text-field v-model="password1" label="New Password" 
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                filled
                :type="showPass ? 'text' : 'password'"
            ></v-text-field>
            <v-text-field v-model="password2" label="Confirmar" 
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                filled
                :type="showPass ? 'text' : 'password'"
            ></v-text-field>
            <div class="text-center">
                <v-btn class="signin-btn" rounded color="teal" dark @click='CambiaPassword()'>Cambiar</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
   <v-snackbar v-model="msgbox" :timeout="timeout" :color="color">
    {{Msg}}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="msgbox = false">Close</v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
import axios from 'axios';
export default {
  name:'Login',
  //components:{ idUser },
  data: () => ({
    contrato:'',
    login: '',
    password: null,
    showPass: false,
    msgbox:false,
    timeout: 3000,
    Msg:'',
    color:'',
    dialog:false,
    password1:'',
    password2:'',
    form_dialog:false,
    form_contrato:'',
    form_usuario:'',

  }),
  mounted(){
    this.inicializar()
  },
  computed: {
    params() {
      return {
        contrato:this.contrato.toUpperCase(),
        login: this.login.toUpperCase(),
        password: this.password
      }
    }
  },
  methods: {
    inicializar(){
      this.contrato='';
      this.login= '';
      this.password= null;
      this.showPass= false;
      this.msgbox= false;
      this.Msg='';
      this.color='';
      this.dialog= false;
      this.password1= '';
      this.password2= '';
      this.form_dialog=false;
      this.form_contrato='';
      this.form_usuario='';
    },
    async OpenFormDialog(){
      this.form_contrato='';
      this.form_usuario='';
      this.form_dialog=true
    },
    async sendPassword(){
      if(this.form_contrato!=null){
        if(this.form_usuario!=null){
          if(this.form_contrato.trim()!='' && this.form_usuario.trim()!=''){
            try {
              const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.form_contrato.toUpperCase()+'/'+ this.form_usuario.toUpperCase());
              const datos=response.data;
              if (datos[0].existe=='si'){
                const myEmail = datos[0].Email;
                const myPass = datos[0].Clave;
                const myBody = "<strong>tu clave registra es:</strong><br/><br/><h3>" + myPass + "</h3>"
                let InstFormData = new FormData();
                InstFormData.append('empresa' , this.form_contrato);
                InstFormData.append('remitente' , 'contacto@bodeganet.cl');
                InstFormData.append('destinatario' , myEmail);
                InstFormData.append('asunto' , 'Recupera Clave');
                InstFormData.append('cuerpo' , myBody);
                const response= await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/server/sender/email/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
                this.Msg='La solicitud fue enviada';
                this.msgbox=true;
                this.color='success';
                this.form_dialog=false;
              } else {
                this.Msg='El Contrato o Usuario no estan correctos, intentelo nuevamente'
                this.msgbox=true;
                this.color='error'
              }
            } catch (error) {
              console.log(error);
              this.Msg='No es posible enviar contraseña'
              this.msgbox=true;
              this.color='error'
            }
          } else {
            this.Msg='Ingrese los datos solicitados para enviar contraseña'
            this.msgbox=true;
            this.color='error'
          }
        } else {
          this.Msg='Ingrese los datos solicitados para enviar contraseña'
          this.msgbox=true;
          this.color='error'
        }
      } else {
        this.Msg='Ingrese los datos solicitados para enviar contraseña'
        this.msgbox=true;
        this.color='error'
      }
    },
    async submit() {
      const x=true;
      if (x){
        if(this.login!='' & this.password!=null){
          // const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/contrato/'+ this.contrato);
          // const datos=response.data;
          const datos='ok';
          if (datos=="ok") {
            const response1 = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/contrato/'+ this.contrato.toUpperCase()+'/'+ this.login.toUpperCase() +'/'+this.password);
            const datos1=response1.data;
            console.log(datos1);
            if (datos1=="ok") {
              if(this.password=='123456'){
                // this.Msg='Debe Cambiar Clave'
                // this.msgbox=true;
                // this.color='orange'
                this.dialog=true;          
              } else {
                //creamos la credencial
                const token = await this.createLogin();
                if(token!='error'){
                  localStorage.removeItem('token');
                  localStorage.setItem("token", token);
                  this.$router.push({name:'Home',params:{idUsuario:this.login.toUpperCase(),idContrato:this.contrato.toUpperCase()}})
                }
              }
            } else {
              this.Msg='Usuario invalido o bloqueado'
              this.msgbox=true;
              this.color='red'
            }          
          } else {
            this.Msg='Contrato Incorrecto'
            this.msgbox=true;
            this.color='red'
          }
        }
      } else {
        this.Msg='Licencia Caducada';
        this.msgbox=true;
        this.color='red'
      }
    },
    async createLogin(){
      let params;
      let headers;
      try {
          params = {
            "contrato":this.contrato.toUpperCase(),
            "user":this.login.toUpperCase()
          };
          headers = {
              'Content-Type': 'application/json',
          };
          const response = await axios.post('http://www.app.bodeganet.cl/ApiEcommerce/public/api/v1/create/token/',params,{headers});
          const dato = response.data;
          return dato.Token;
      } catch (error) {
          return 'error' 
      }
    },
    OpenDialog(){
      if (this.contrato!='' && this.login!=''){
        this.password='';
        this.password1='';
        this.password2='';
        this.dialog=true
      } 
      else {
        this.Msg='ingrese Contrato y Usuario'
        this.msgbox=true;
        this.color='red'
      }
    },
    async CambiaPassword(){
      if(this.password1=='123' || this.password1=='123456'){
        this.Msg='Clave invalida'
        this.msgbox=true;
        this.color='red'
      }
      else {
        if((this.password1.length!=0 || this.password2.length!=0)){
          if(this.password1==this.password2){
            //verificamos la existencia del login
            const response = await axios.get('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/usuarios/'+this.contrato.toUpperCase()+'/'+ this.login.toUpperCase());
            const datos=response.data;
            if (datos[0].existe=='si'){
              if(datos[0].Contrato==this.contrato.toUpperCase()){
                //modificamos clave
                const response0=await axios.put('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/login/password/'+this.contrato.toUpperCase()+'/'+ this.login.toUpperCase(),{'pass':this.password1});
                const valido=response0.data;
                if(valido=='si'){
                  this.$router.push({name:'Home',params:{idUsuario:this.login.toUpperCase(),idContrato:this.contrato.toUpperCase()}})
                } else {
                  this.Msg='Sin Cambios, intentelo mas tarde'
                  this.msgbox=true;
                  this.color='red'
                }
              } else {
                this.Msg='Contrato invalido'
                this.msgbox=true;
                this.color='red'
              }
            } else {
              this.Msg='Usuario invalido'
              this.msgbox=true;
              this.color='red'
            }
          } else {
            this.Msg='Claves no coinciden'
            this.msgbox=true;
            this.color='red'
          }
        } else {
            this.Msg='Ingrese Claves'
            this.msgbox=true;
            this.color='red'
        }
      }
    },
    clear() {
      // you can use this method to clear login form
      this.email = ''
      this.password = null
      this.$refs.observer.reset()
    }
  }
}
</script>

